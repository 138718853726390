import { Outlet } from '@remix-run/react';
import { FC } from 'react';
import backlighting from '~/assets/images/backgrounds/backLighting.png';
import { NotificationContextWrapper } from '../root/context';

export const SignInLayoutPage: FC = () => {
  return (
    <NotificationContextWrapper>
      <div className="absolute inset-0 flex items-center justify-center overflow-hidden">
        <img
          src={backlighting}
          className="flex-initial blur-3xl select-none"
          style={{
            width: 1418,
            height: 1418,
          }}
        />
      </div>

      <Outlet />
    </NotificationContextWrapper>
  );
};
